export default function Accordion(triggerSelector, itemsSelector) {
  const btns = document.querySelectorAll(triggerSelector)
  const blocks = [...document.querySelectorAll(itemsSelector)]

  btns.forEach((btn, index) => {
    const currentBlock = blocks[index]
    btn.addEventListener('click', () => {
      btn.classList.toggle('active')

      currentBlock.classList.toggle('Accordion__tab--open')

      if (currentBlock.classList.contains('Accordion__tab--open')) {
        currentBlock.style.maxHeight = currentBlock.scrollHeight + 'px'
      } else {
        currentBlock.style.maxHeight = '0px'
      }
    })
  })
}
