export default function askMeChat() {
  const currentCodeLang = document.querySelector('html').getAttribute('lang')
  const btn = document.querySelector('.ask-btn')
  const cookieUserId = getCookieWithName('mmUserId')
  const cookieUserName = getCookieWithName('mmUserName')
  const cookieUserEmail = getCookieWithName('mmEmail')

  function getCookieWithName(cname) {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  const ask_chat_script = document.createElement('script')

  ask_chat_script.setAttribute('src', 'https://admin-ask.globalcloudteam.com/web-askme/index.js')

  ask_chat_script.onload = function () {
    if (!cookieUserId && !cookieUserName && !cookieUserEmail) {
      window.AskMeChatBot.init({
        configWebUi: {
          keyBrand: 'ce8cf628-dec9-43aa-b367-f2e392bc54f8',
        },
      })
    } else {
      window.AskMeChatBot.init({
        configWebUi: {
          keyBrand: 'ce8cf628-dec9-43aa-b367-f2e392bc54f8',
        },
        userInfo: {
          userName: cookieUserName,
          userEmail: cookieUserEmail,
          userId: cookieUserId,
        },
      })
    }

    setTimeout(function () {
      window.AskMeChatBot.setLocale(currentCodeLang)
      window.AskMeChatBot.showChatWindow()
      document.body.classList.remove('ask-chat-hide')
    }, 1000)
  }

  btn.addEventListener('click', function () {
    document.body.classList.add('ask-chat-hide')
    this.remove()
    document.body.append(ask_chat_script)
  })
}
