/* eslint-disable import/no-extraneous-dependencies */
import 'webp-in-css/polyfill'
import MenuAnimation from './helpers/animateMenu/animateMenu';
import mobileBurger from './modules/mobileBurger';
import Accordion from './helpers/accordion/accordion';
import addGtm from './helpers/removeGTM/remove-gtm';
import askMeChat from './modules/askMeChat';

if (document.readyState !== 'loading') {
  console.warn('main script not load DOMContentLoaded')
  // eslint-disable-next-line no-use-before-define,no-undef
  globalInitLazy()
} else {
  // eslint-disable-next-line no-use-before-define
  window.addEventListener('DOMContentLoaded', () => {
    console.warn('main script load DOMContentLoaded')
    // eslint-disable-next-line no-use-before-define
    globalInitLazy()
  })
}

function globalInitLazy() {

  const menus = () => {
    const header = document.querySelector('.header')
    MenuAnimation(header)
  }
  menus()

  const accordionNavMob = () => {
    Accordion('.mobile-menu__title', '.mobile__sub-menu')
  }

  const footerNavMob = () => {
    if (window.innerWidth < 1200) {
      Accordion('.footer__nav-title', '.footer__nav-items-wrap')
    }
  }

  const scrollToTop = () => {
    const btnScrollToTop = document.querySelector('.arrow-up')
    btnScrollToTop.addEventListener('click', function () {
      window.scrollTo({
        top: 0,
        let: 0,
        behavior: 'smooth',
      })
    })
  }

  scrollToTop()
  accordionNavMob()
  footerNavMob()
  mobileBurger()
  askMeChat()
}


// window.addEventListener('click', () => {
//   addGtm();
// });
// window.addEventListener('mousemove', () => {
//   addGtm();
// });
// window.addEventListener('scroll', () => {
//   addGtm();
// });