export default function mobileBurger() {
  const burger = document.querySelector('.mobile-menu__burger')
  const menuBlock = document.querySelector('.mobile-menu')
  const closeMenu = document.querySelector('.mobile-menu__close-button')
  const body = document.querySelector('body')

  burger.addEventListener('click', function () {
    menuBlock.classList.add('mobile-menu__open')
    body.classList.add('overflow')
  })

  closeMenu.addEventListener('click', function () {
    menuBlock.classList.remove('mobile-menu__open')
    body.classList.remove('overflow')
  })
}
